import React from "react";

function StickyNav(props) {
    return (
        <ul className='bottom-Headline'>
        <li className='bottom-Headline__Sub'><a href={'/' + props.firstTo}>{props.first}</a></li>
        <li className='bottom-Headline__Main'>{props.main}</li>
        <li className='bottom-Headline__Sub'><a href={'/' + props.secTo}>{props.sec}</a></li>
    </ul>
    );
}

export default StickyNav;
const cards = [
    {
        id: 1,
        img: 'https://cloud.rezepoge.de/apps/files_sharing/publicpreview/jdEqTMGY4KbrHMG?file=&fileId=269827&x=1920&y=1080&a=true',
        work: 'REGIE & KAMERA',
        title: 'CORONA ERKLÄRVIDEO',
        type: 'IMAGEFILM',
        preview: 'https://cloud.rezepoge.de/s/ePtMdgBtrspLDCf/download/Erkl%C3%A4rvideo%20Corona%20_%20D%C3%BCren%20Powervolleys%20_%202020%20-%20Preview.mp4',
        video: 'https://cloud.rezepoge.de/s/HFka2F9FjGQHdKQ/download/Erkl%C3%A4rvideo%20Corona%20_%20D%C3%BCren%20Powervolleys%20_%202020.mp4',
        description: <>Das Corona Erklär Video für die Powervolleys in DÜren, war eines der 
        spontansten Projekte die ich hatte. Innerhalb von 48 Stunden ist der Autrag rein gekommen, und es 
        musste ohne Skript, oder ähnliches aufgenommen werden. Vor Ort hatten wir 
        begrenzt Zeit, mit einer Hand voll Profis des Teams, Scenen Aufzunehmen. <br/><br/>
        Da die Corona Regeln sich in der Zeit häufig geändert haben, ist das Video allerdings 
        nicht mehr aktuell, weshalb es nie hochgeladen wurde.
        Für die kurze Aufnahmezeit, ist das Video wunderbar geworden und ich bin sehr Stolz auf das Ergebnis! </>
    },
    {
        id: 2,
        img: 'https://cloud.rezepoge.de/apps/files_sharing/publicpreview/DrSGA4sZPJpqgdZ?file=&fileId=269810&x=1920&y=1080&a=true',
        work: 'REGIE & SCHNITT',
        title: 'LASERSCHWERT',
        type: 'ATMOSPHÄRE',
        preview: 'https://cloud.rezepoge.de/s/nYHrqCKCo8f7bF8/download/laserschwert%20-%20Preview.mp4',
        video: 'https://cloud.rezepoge.de/s/48SrDKc6pdyxQzc/download/Lightsaber%20Fanmade.mp4',
        description: <>Etwas was ich schon immer mal machen wollte, war einen eigenen Lichtschwertkampf, 
        und da ist er! Das Video ist ein kleiner Teil von einem großen Projekt, was durch personelle 
        Schwierigkeiten und der zu dem Zeitpunkt laufenden Pandemie
        nicht abgeschlossen werden konnte. <br/><br/> Hier geht auch eine dank an Semin Erol raus, der ehmalig bei 
        John Reed Fitness gearbeitet hat, da er mir die Lokation zur verfügung gestellt hat.  </>
    },
    {
        id: 3,
        img: 'https://cloud.rezepoge.de/apps/files_sharing/publicpreview/FKFbeYjTrYSA8MJ?file=&fileId=269801&x=1920&y=1080&a=true',
        work: 'KAMERA',
        title: '15 / 16 / 17 - SCJ',
        type: 'MUSIKVIDEO',
        preview: 'https://cloud.rezepoge.de/s/BArxggMzB93kxxA/download/SCJ%20-%2015%2016%2017%20%28Official%20Video%29%20-%20Preview.mp4',
        video: 'https://cloud.rezepoge.de/s/F52fBDDCNnGbz3s/download/SCJ%20-%2015%2016%2017%20%28Official%20Video%29.mp4',
        description: <>
        Das erste Projekt mit einem guten Freund vom Berufskolleg. Das Video war einer der ersten Projekte die mit 
        viel Planung und einen vernünftigen Script abgedreht wurde. <br/><br/>In Köln hatte ich eine menge Spaß. Eine fantastische Erfahrung
        und ein noch besseres Musikvideo!</>
    },
];

export default cards;
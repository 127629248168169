import gsap from 'gsap';
import React, {useRef, useEffect} from 'react';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

import Header from './Header';
import PersonalDesc from './PersonalDesc';
import Card from './Card';
import cards from '../data/cards';
import Footer from './Footer';
import textBox from '../data/textBox';
import TextBox from './TextBox';
import ScrollTop from './ScrollTop';
import LinkTo from './LinkTo';
import StickyNav from './StickyNav';

import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Contact from './Contact';
import Formular from './Formular';

function App() {

    gsap.registerPlugin(ScrollTrigger);
    
    const ref = useRef(null);

    useEffect( _ => {

        gsap.to(ref.current, {
            paddingTop: '40px',
            opacity: '1',
            scrollTrigger: {
                trigger: 'section',
                start: 'top bottom',
                end: 'top center',
                scrub: 0,
            }
        });
    });

    return <Router>
        <div id="wrapper">
            <Routes>
                <Route path="/" element={
                    <div>
                        <div className='target'>
                        <Header 
                            data={'Start'}
                            title={'Andre Faassen'}
                            elem={['Regie', 'Kamera', 'Schnitt']}/>
                        </div>
                        <div className='target' id='content'>
                            <section>
                                <div className='layout' ref={ref}>
                                    <PersonalDesc/>
                                    {cards.map((item, index) => {
                                        return <Card 
                                            key={item.id}
                                            id={index}
                                            img={item.img}
                                            preview={item.preview}
                                            video={item.video}
                                            work={item.work}
                                            title={item.title}
                                            type={item.type}
                                            description={item.description}/>
                                    })}
                                </div>
                            </section>
                            <ScrollTop link='#headline'/>
                            <StickyNav first={'Kontakt'} firstTo={'Kontakt'} main={'Galerie'} sec={'Leistungen'} secTo={'Leistungen'}/>
                        </div>
                    </div>
                    }/>
                <Route path="/Leistungen" element={
                    <div>
                        <div className='target' id='headline'>
                            <Header 
                                    data={"Leistungen"}
                                    title={'Leistungen'}
                                    elem={['Andre', 'Faassen']}/>
                            </div>
                             <div className='target' id='content'>
                            <section>
                                <div className='layout' ref={ref}>
                                    {textBox.map((item) => {
                                        return <TextBox title={item.title} text={item.text} key={item.id} icon={item.icon}/>
                                    })}
                                <LinkTo to={'/Kontakt'} text={'Weiter zu Kontakt'}/>
                                </div>
                                <ScrollTop link='#headline'/>
                            </section>
                            <StickyNav first={'Galerie'} firstTo={''} main={'Leistungen'} sec={'Kontakt'} secTo={'Kontakt'}/>
                        </div>
                    </div>}/>
                <Route path="/Kontakt" element={ 
                   <div>
                    <div className='target' id='headline'>
                        <Header 
                            data={"Kontakt"}
                            title={'Kontakt'}
                            elem={['Faassen', 'Andre']}/>
                    </div>
                    <div className='target' id='content'>
                        <section>
                            <div className='layout' ref={ref}>
                                <TextBox 
                                    title="Ihnen gefällt mein Portfolio?"
                                    text="Dann melden Sie sich gerne über 
                                        das Kontaktformular bei mir."/>
                                <Formular/>
                                <Contact/>
                            </div>
                            <ScrollTop link='#headline'/>
                        </section>
                        <StickyNav first={'Leistungen'} firstTo={'Leistungen'} main={'Kontakt'} sec={'Galerie'} secTo={''}/>
                    </div>
                </div>}/>
                <Route path="/Impressum" element={
                    <div>
                    <div className='target' id='headline'>
                    <Header 
                        data={'Impressum'}
                        title={'Impressum'}
                        elem={['', '', '']}/>
                    </div>
                    <div className='target' id='content'>
                        <section className='check'>
                            <div className='layout' ref={ref}>
                                <div className="fullWidth-Box">
                                    <h2>Impressum</h2>

                                    <p>Angaben gemäß § 5 TMG:</p>

                                    <p>
                                        Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
                                        Mark Dzierlatka
                                    </p>

                                    <h3>Kontakt:</h3>
                                    Mark Dzierlatka <br />
                                    Telefon: 0176 76705060 <br />
                                    E-Mail: dzierlatka02@gmail.com <br />

                                    <h3>Haftungsausschluss:</h3>
                                    <p>
                                        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit 
                                        und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir 
                                        gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. 
                                        Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder 
                                        gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine 
                                        rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von 
                                        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung 
                                        ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. 
                                        Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                                    </p>
                                
                                    <h3>Haftung für Links:</h3>
                                    <p>
                                        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir 
                                        keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. 
                                        Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten 
                                        verantwortlich. Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die 
                                        Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber 
                                        verantwortlich.
                                    </p>
                                
                                    <h3>Urheberrecht</h3>
                                    <p>
                                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen 
                                        Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der 
                                        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. 
                                        Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. 
                                        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte 
                                        Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem 
                                        auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. 
                                        Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                                    </p>

                                </div>
                            </div>
                        </section>
                        <ScrollTop link='#headline'/>
                    </div>
                </div>
                }/>
                <Route path="/Datenschutz" element={
                    <div>
                        <div className='target'>
                        <Header 
                            data={'Datenschutz'}
                            title={'Datenschutz'}
                            elem={['', '', '']}/>
                        </div>
                        <div className='target' id='content'>
                            <section className='check'>
                                <div className='layout' ref={ref}>
                                <div className="fullWidth-Box">
                                    <h2>Datenschutzerklärung</h2>

                                    <p>
                                        Willkommen auf meiner Webseite. Der Schutz Ihrer persönlichen Daten 
                                        ist uns sehr wichtig. Nachfolgend informieren wir Sie über die Erhebung, Verarbeitung 
                                        und Nutzung personenbezogener Daten bei Nutzung unserer Website.
                                    </p>

                                    <h3>Erhebung und Verarbeitung personenbezogener Daten</h3>

                                    <p>
                                        Bei jedem Zugriff auf unsere Website werden automatisch Daten über den Zugriff erfasst. 
                                        Diese Daten, die sogenannten Server-Logfiles, enthalten u.a. den Namen der abgerufenen Datei, 
                                        Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider. Diese Daten
                                         dienen lediglich statistischen Auswertungen und zur Verbesserung unseres Angebots.
                                    </p>

                                    <h3> Verwendung von Cookies</h3>

                                    <p>
                                        Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu 
                                        ermöglichen, verwenden wir auf verschiedenen Seiten sogenannte Cookies. Hierbei handelt es sich 
                                        um kleine Textdateien, die auf Ihrem Endgerät gespeichert werden. Einige der von uns verwendeten 
                                        Cookies werden nach Ende der Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht 
                                        (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns, Ihren 
                                        Browser beim nächsten Besuch wiederzuerkennen (sog. dauerhafte Cookies).

                                        <br /><br />

                                        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden 
                                        und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell 
                                        ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. 
                                        Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
                                    </p>

                                    <h3>Verarbeitung von Daten durch das Kontaktformular</h3>

                                    <p>
                                        Unsere Website verfügt über ein Kontaktformular, das von uns bereitgestellt wird, um es Ihnen zu 
                                        erleichtern, uns zu kontaktieren. Die von Ihnen eingegebenen Daten, wie Name und E-Mail-Adresse, 
                                        werden an uns übermittelt und mithilfe des Anbieters EmailJS verarbeitet. Wir verwenden Ihre 
                                        Daten ausschließlich zur Bearbeitung Ihrer Anfrage und zur Kommunikation mit Ihnen. Wir geben
                                        Ihre Daten nicht an Dritte weiter und speichern sie ausschließlich so lange, wie es für die 
                                        Erfüllung unserer vertraglichen und gesetzlichen Verpflichtungen erforderlich ist.
                                    </p>

                                    <h3>Verwendung von Cloudflare</h3>

                                    <p>
                                        Unsere Website nutzt den Dienstleister Cloudflare, um ihre Leistung und Sicherheit zu v
                                        erbessern. Cloudflare sammelt Daten über die Nutzung unserer Website durch Benutzer, 
                                        einschließlich IP-Adresse, Browserverlauf und Verhaltensdaten. Diese Daten werden von
                                        Cloudflare verarbeitet und genutzt, um die Leistung und Sicherheit unserer Website zu 
                                        verbessern. Die Datenschutzrichtlinien von Cloudflare finden Sie hier:  
                                        <a href="https://www.cloudflare.com/de-de/privacypolicy/" target="_blank"> Cloudfares Datenschutzrichtlinie</a>
                                    </p>
                                    </div>
                                </div>
                            </section>
                            <ScrollTop link='#headline'/>
                        </div>
                    </div>
                    }/>
            </Routes>
            <Footer/>
        </div>
    </Router>
}

export default App;
import React, {useRef, useEffect} from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

function Headline(props) {

    gsap.registerPlugin(ScrollTrigger);
    
    const ref = useRef(null);

    useEffect( _ => {

        gsap.to(ref.current, {
            opacity: 0,
            y: '100px',

            scrollTrigger: {
                trigger: 'section',
                start: 'top bottom',
                end: 'top center',
                scrub: 0,
            }
        });
    });

    return <div className="head" ref={ref}>
        <h1 className='head__Main'> {props.value.main} </h1>
        {props.value.sec.map((item) => {
            return <h2 className={"head__" + item + " item"} key={item}>{item}</h2>
        })}
    </div>
}

export default Headline;
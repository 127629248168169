import React, { useState } from "react";
import Modal from "./Modal";

function ReadMore(props) {

    const [visible, setVisible] = useState(false);

    return (
        <span>
            <div className="readMore" onClick={ _ => setVisible(true)}>
                Mehr lesen -&gt;   
            </div>
            <Modal 
                open={visible} 
                close={ _ => setVisible(false)} 
                set={'text'} 
                vid={''}
                img={props.img}
                title={props.title}
                description={props.description}/>
        </span>
    );
}

export default ReadMore;
import React from "react";

function Contact() {
    return (
        <div className="contact">
            <h3>Mail</h3>
            <p>official_andrefaassen@gmx.de</p>
        </div>
    );
}

export default Contact;

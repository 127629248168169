import React, {useRef, useEffect} from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import socials from '../data/socials';

function Links() {
    gsap.registerPlugin(ScrollTrigger);
    
    const ref = useRef(null);

    useEffect( _  => {

        gsap.to(ref.current, {
            opacity: 0,
            y: '20px',

            scrollTrigger: {
                trigger: 'section',
                start: 'top bottom',
                end: 'top center',
                scrub: 0,
            }
        });

    });
    
    return <div className='social' ref={ref}>
        {socials.map((item) => {
            return <a href={item.href}  key={item.id} target="_blank" rel="noreferrer">
                <svg 
                className="social__Item"
                id={item.name} 
s               alt={item.alt} />
            </a>
        })}
    </div>
}

export default Links;
import React, {useRef, useEffect} from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

function LinkTo(props) {

    gsap.registerPlugin(ScrollTrigger);
    
    const ref = useRef(null);

    useEffect( _  => {

        gsap.to(ref.current, {
            opacity: 1,
            marginTop: '42px',
            scrollTrigger: {
                trigger: 'section',
                start: 'top bottom',
                end: 'top center',
                scrub: 0,
            }
        });

    });

    return (
        <a className="linkTo" href={props.to} ref={ref}>
            <span className="linkTo__Text">{props.text}</span>
        </a>
    );
}

export default LinkTo;
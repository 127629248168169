const personalData = {
    img: 'https://cloud.rezepoge.de/apps/files_sharing/publicpreview/iDxF8Ywpo9bcftR?file=&fileId=269836&x=1920&y=1080&a=true',
    title: 'Andre Faassen',
    description:
        <>Ich habe mich auf den Beruf des Mediengestalters Bild/Ton spezialisiert. <br/>In diesem Bereich kann ich meiner Kreativität 
        freien lauf lassen und meine Ideen umsetzen, allerdings möchte ich Ihre Ideen hören und zusammen kreieren wir ein geniales 
        Endprodukt. <br/><br/>Ich freue mich auf Ihre Nachricht!</>,
    preview: `Ich habe mich auf den Beruf des Mediengestalters Bild/Ton spezialisiert. In diesem Bereich kann ich meiner Kreativität ..`
}

export default personalData;
import React , {useEffect, useRef} from "react";

import Headline from './Headline';
import Navigation from './Navigation';
import Links from './Links';

import gsap from "gsap";
import ScrollTrigger from 'gsap/dist/ScrollTrigger'


function Header(props) {

    gsap.registerPlugin(ScrollTrigger);
    
    const ref = useRef(null);

    useEffect( _  => {

        gsap.to(ref.current, {
            filter: 'brightness(0.6)',
            scrollTrigger: {
                trigger: 'section',
                start: 'top 80%',
                end: 'top 0%',
                scrub: 0,
            }
        });

    });

    const arrow = useRef(null);

    useEffect( _  => {

        gsap.to(arrow.current, {
            marginBottom: '-50px',
            opacity: 0,
            scrollTrigger: {
                trigger: 'section',
                start: 'top bottom',
                end: 'top center',
                scrub: true,
            }
        });

    });

    return (
        <header id='headline' className={props.data + 'cls'} ref={ref}>
            <div
                className="background-Header" 
                id={props.data}> </div>
            <Headline value={
                {
                    main: props.title,
                    sec: props.elem
                }
            }/>
            <Navigation/>
            <a className='scrollDown' href='#content' ref={arrow}>
                <div className='scrollDown__Img' alt=''></div>
            </a>
            <Links/>
        </header>
    );
}

export default Header;
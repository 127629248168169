import React, {useState, useRef, useEffect} from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import personalData from '../data/personalData';
import Modal from './Modal';
import ReadMore from './ReadMore';

function PersonalDesc() {

    const [visible, setVisible] = useState(false);

    gsap.registerPlugin(ScrollTrigger);
    
    const ref = useRef(null);

    useEffect( _  => {

        gsap.to(ref.current, {
            opacity: 1,
            marginTop: '0',

            scrollTrigger: {
                trigger: 'section',
                start: 'top bottom',
                end: 'top center',
                scrub: true,
            }
        });
    });

    return <div className="personal" ref={ref}>
        <div className="personal__Img" onClick={ _ => setVisible(true)}>
            <img className="personal__Img--Item" src={personalData.img} alt=""/>
        </div>
        <h2 className='personal__Title'> {personalData.title} </h2>
        <p className="personal__Description"> {personalData.preview} 
            <ReadMore
                title={personalData.title}
                img={personalData.img}
                description={personalData.description}/>
        </p>

        <Modal 
            open={visible} 
            close={ _ => setVisible(false)} 
            set={'text'} 
            vid={''}
            img={personalData.img}
            title={personalData.title}
            description={personalData.description}/>
    </div>
}

export default PersonalDesc;
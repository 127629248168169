const socials = [
    {
        id: 1,
        name: 'Youtube',
        alt: '',
        img: '',
        href: 'https://www.youtube.com/@and3rz0ne75'
    },
    {
        id: 2,
        name: 'Instagram',
        alt: '',
        img: '',
        href: 'https://www.instagram.com/and3rz0ne/?hl=de'
    },
]

export default socials;
const textBox = [
    {
        id: 1,
        title: 'Videoschnitt',
        text: <>
        Ich liebe es Videos zu schneiden! In den Schnittprogrammen kennt meine 
        Kreativität keine Grenzen. Geben Sie mir gerne Ihre Datein, und mit einer 
        kleinen Vorbesprechung, baue ich Ihnen das was Sie haben wollen! 
        <br/><br/>
        Kommunikation ist der Schlüssel zu einem erfolgreichen Endprodukt. 
        Ich benutze Premiere Pro, und After Effekt. Die Adobe Programme 
        helfen mir ein sehr gutes Video zu erschaffen. Adobe ist einer 
        der Besten, wenn nicht der Beste, Anbieter in der Branche.
        <br/><br/>
        Zusammen schaffen wir das!</>,
        icon: '«'
    },
    {
        id: 2,
        title: 'Kamera',
        text: <>Ich benutze aktuell eine Sony Alpha 7S, um Fotos und Videos aufzunehmen.
        Die Kamera hat mich bisher bei all meinen Projekten begleitet. 
        <br/><br/>
        Kombiniert mit einem Gimbal oder Stativ, steht dem Perfekten Endprodukt nichts mehr 
        im Weg!</>,
        icon: '«'

    },
    {
        id: 3,
        title: 'Regie',
        text: <>Ich habe ein eigenes Konzept, um Regie in einer Produktion zu führen. 
        Ich benutze das Programm Notion, welches mir erlaubt Tabellen und Datein in
        einer wunderbaren Übersicht zu erstellen. 
        <br/><br/>
        Diese Übersicht hilft auch Ihnen
        zu verstehen, was genau ich vor habe. Außerdem, können Sie dieses Programm 
        ebenfalls benutzen und Live verfolgen, was der Stand der Dinge ist.</>,
        icon: '«'
    },
];

export default textBox;
import React, { useState} from 'react';
import Modal from './Modal';
import cards from '../data/cards';
import { gsap, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);
function Card(props) {

    gsap.registerPlugin(ScrollTrigger);

    const [modal, setModal] = useState(false);

    const [play, setPlay] = useState(1);

    function onHandle() {
        setPlay( _ => {
            return 0;
        });
    }

    function onLeave() {
        setPlay(() => {
            return 1;
        });
    }


    return <div className='card'>
        <div className="card__Img" onClick={ _ => setModal(true)} onMouseOver={onHandle} onMouseLeave={onLeave}>
        <img className="card__Img--Item" style={{opacity: play}} src={props.img} alt=""/>
        <video className="card__Img--Hover" style={{opacity: (play === 1) ? 0 : 1}} autoPlay={true} muted={true} loop={true} src={props.preview} width="100%"></video>
        </div>
        <h4 className='card__Work'>{props.work}</h4>
        <h3 className='card__Title'>{props.title}</h3>
        <h4 className='card__Type'>{props.type}</h4>
        <Modal 
            open={modal} 
            close={ _ => setModal(false)} 
            set={'video'} 
            vid={cards[props.id]}
            title={cards[props.id].title}
            description={cards[props.id].description}/>
    </div>
}

export default Card;
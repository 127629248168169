import React, {useRef, useEffect, useState} from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { Link, useLocation } from 'react-router-dom';

import navElem from '../data/navElem';

function Navigation() {

    gsap.registerPlugin(ScrollTrigger);
    
    const ref = useRef(null);

    useEffect( _ => {

        gsap.to(ref.current, {
            opacity: 0,
            y: '20px',

            scrollTrigger: {
                trigger: 'section',
                start: 'top bottom',
                end: 'top center',
                scrub: 0,
            }
        });

    });

    const location = useLocation();
    const [active, setActive] = useState('');

    useEffect(() => {
        navElem.forEach((item) => {
            if (location.pathname === item.link) {
                setActive(item.key);
            }
        });
    }, [location]);

    return <nav className='navigation' ref={ref}>
        <ul className='navigation__List'>
            {navElem.map((items) => {
                return (
                    <Link to={items.link} key={items.id}>
                        <li
                            className={`navigation__List--Item ${
                                active === items.key ? 'active' : ''
                            }`}
                            id={items.key}
                        >
                            {items.title}
                        </li>
                    </Link>
                );
            })}
        </ul>
    </nav>
}

export default Navigation;
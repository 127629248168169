import React, {useRef, useEffect} from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

function TextBox(props) {

    gsap.registerPlugin(ScrollTrigger);
    
    const ref = useRef(null);

    useEffect( _ => {

        gsap.to(ref.current, {
            marginTop: '0',
            scrollTrigger: {
                trigger: 'section',
                start: 'top bottom',
                end: 'top center',
                scrub: 0,
            }
        });

    });

    const site = useRef(null);

    useEffect( _ => {

        gsap.to(site.current, {
            marginLeft: '16px',
            scrollTrigger: {
                trigger: 'section',
                start: 'top bottom',
                end: 'top center',
                scrub: 2,
            }
        });

    });

    return <div className="textBox" ref={ref}>
        <h3 className="textBox__Title">
            {props.title}
            <span className="textBox__Title--Icon" ref={site}>{props.icon}</span>
        </h3>
        <h4 className="textBox__Text">
            {props.text}
        </h4>
    </div>
}

export default TextBox;
import React from "react";

const Modal = ({open, close, vid, set, title, description, img}) => { 
    
    if (!open) {
        return null;
    }

    if(open && set === 'video') {
        return (
            <div className="overlay" id="target_Pop">
                <div className="popUp">
                    <video 
                        className="popUp__Video"
                        controls
                        src={vid.video}></video>;
                    <div className="popUp__Close" onClick={close}>
                        Schliessen
                    </div>
                    <div className="popUp__Text">
                    <h2 className="popUp__Text--Title"> 
                            {title}
                        </h2>
                        <p className="popUp__Text--Content"> 
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    else if(open && set === 'text') {
        return (
            <div className="overlay" id="target_Pop">
            <div className="popUp">
                <img className="popUp__Image" src={img} alt="krass"/>
                <div className="popUp__Close" onClick={close}>
                    Schliessen
                </div>
                <div className="popUp__Text">
                    <h2 className="popUp__Text--Title"> 
                        {title}
                    </h2>
                    <p className="popUp__Text--Content"> 
                        {description}
                    </p>
                </div>
            </div>
        </div>
        );
    }
}

export default Modal;
import React, {useRef} from "react";
import emailjs from '@emailjs/browser';

function Formular() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_6lw5g2i', 'template_y0vohc9', form.current, '1QzqgmJdMmHKwK4sZ')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

          document.querySelector('body').innerHTML+=`
          <div class="submit submit-Start">
            <div class="submit__Content">
                <h3>E-Mail gesendet!</h3>
                <p>Ich werde mich in Kürze mit Ihnen in Verbindung setzen.
                <br/><br/>
                Viele Grüße <br/>
                Andre Faassen
                </p>
            </div>
          </div>`;

          setTimeout(() => {
              window.location="/Kontakt";
          }, 4000);

          e.target.reset();

      };

    return <form 
                className="form" 
                ref={form} 
                onSubmit={sendEmail}>
                <label 
                    className="form__Label">Ihr Name</label>
                <input 
                    className="form__Input" 
                    type="text" 
                    required 
                    name="user_name"/>
                <label 
                    className="form__Label">Ihre E-Mail-Addresse</label>
                <input 
                    className="form__Input" 
                    type="email" 
                    required 
                    name="user_email"/>
                <label 
                    className="form__Label">Betreff</label>
                <input 
                    className="form__Input" 
                    type="text" 
                    required 
                    name="subject"/>
                <label 
                    className="form__Label">Ihre Nachricht</label>
                <textarea 
                    className="form__Input" 
                    id="textBox" 
                    type="text" 
                    required 
                    name="message"></textarea>
                <input
                    className="form__Submit" 
                    type="submit" 
                    value="Senden >"/>
    </form>
}

export default Formular;
const navElem = [
    {
        id: 1,
        title: 'Galerie',
        key: 'first',
        link: '/'
    },
    {
        id: 2,
        title: 'Leistungen',
        key: 'second',
        link: '/Leistungen'
    },
    {
        id: 3,
        title: 'Kontakt',
        key: 'third',
        link: '/Kontakt'
    }
];

export default navElem;
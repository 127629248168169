import React from 'react';

function Footer() {
    return <footer className='end'>
        <a href="https://www.instagram.com/mark_heisse_ich/?hl=de" 
            target={'_blank'} 
            className='end__Ad' rel="noreferrer">Webdesigner - Mark Dzierlatka</a>
        <span className='end__Rights'>Copyright @2023</span>
        <div className='end__Catch'>
            <a href="/Datenschutz" className='end__Catch--Item'>Datenschutz</a>
            <a href="/Impressum" className='end__Catch--Item'>Impressum</a>
        </div>
    </footer>
}

export default Footer;